<template>
  <div class="card-contract-info">
    <ListRow :columns="columns" vertical half-gap />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import ListRow from "@/components/common/list/ListRow.vue";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}
const props = defineProps<IProps>();

const columns = [
  {
    header: "契約プラン",
    text: props.companyInvoice.labels.contract_plan,
  },
  {
    header: "年次契約",
    text: `${props.companyInvoice.annually_contract_count.toLocaleString()} ID`,
  },
  {
    header: "年次契約備考",
    text: props.companyInvoice.annually_contract_memo || '-',
  },
  {
    header: "月次契約",
    text: `${props.companyInvoice.monthly_contract_count.toLocaleString()} ID`,
  },
  {
    header: "月次契約備考",
    text: props.companyInvoice.monthly_contract_memo || '-'
  },
];
</script>

<style lang="scss" scoped></style>
