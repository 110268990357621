<template>
  <Loading v-if="!isLoaded" :is-loading="!isLoaded" is-fixed />
  <CardWrap v-else>
    <Loading v-if="isUpdating" :is-loading="isUpdating" is-fixed />
    <CompanyCardHeader />

    <div class="account-edit-form">
      <ListRowWrap class="list-row-wrap" :vertical="!isPcView">
        <ListColumn header="アカウント名">
          <Input 
          type="text" id="company_name" v-model="company.name" disabled />
        </ListColumn>
        <ListColumn header="請求管理対象">
          <Select
            v-model="company.company_setting.invoice_targetted"
            :options="[
              { label: '対象', value: 'true' },
              { label: '対象外', value: 'false' },
            ]"
          />
        </ListColumn>
      </ListRowWrap>

      <TitleText text="契約情報" with-icon>
        <IconContract class="icon" />
      </TitleText>

      <ListRowWrap class="list-row-wrap" :vertical="!isPcView">
        <ListColumn header="契約プラン">
          <Select
            v-model="company.contract_plan"
            :options="[
              { label: 'ツールプラン', value: 'simple_plan' },
              { label: 'ベーシックプラン', value: 'basic_plan' },
            ]"
            disabled
          />
        </ListColumn>
        <ListColumn header="年次契約数" style="grid-column-start: 1">
          <Input
            type="text"
            id="annually_contract_count"
            v-model="company.company_setting.annually_contract_count"
            disabled
            with-icon
          >
            <span>ID</span>
          </Input>
        </ListColumn>
        <ListColumn header="年次契約の期限">
          <VueDatePicker
            v-model="company.company_setting.annually_contract_expired_date"
            format="yyyy/MM/dd"
            model-type="yyyy-MM-dd"
            week-start="0"
            :enable-time-picker="false"
            locale="ja"
            auto-apply
            text-input
            disabled
            :clearable="false"
          >
            <template #input-icon>
              <IconCalendar class="input-slot-image" />
            </template>
          </VueDatePicker>
        </ListColumn>
        <ListColumn header="年次契約の備考">
          <Input
            type="text"
            id="annually_contract_memo"
            v-model="company.company_setting.annually_contract_memo"
          />
        </ListColumn>
        <ListColumn header="月次契約数">
          <Input
            type="text"
            id="monthly_contract_count"
            v-model="company.company_setting.monthly_contract_count"
            with-icon
            disabled
          >
            <span>ID</span>
          </Input>
        </ListColumn>
        <div></div>
        <ListColumn header="月次契約の備考">
          <Input
            type="text"
            id="monthly_contract_memo"
            v-model="company.company_setting.monthly_contract_memo"
          />
        </ListColumn>
      </ListRowWrap>

      <TitleText text="請求情報" with-icon>
        <IconInvoice class="icon" />
      </TitleText>

      <ListRowWrap class="list-row-wrap" :vertical="!isPcView">
        <ListColumn header="請求先（宛名）">
          <Input
            type="text"
            id="invoice_address"
            v-model="company.company_setting.invoice_address"
          />
        </ListColumn>
        <ListColumn header="請求先（To）">
          <Input
            type="text"
            id="invoice_email"
            v-model="company.company_setting.invoice_email"
          />
        </ListColumn>
        <ListColumn header="請求書送付方法">
          <Select
            v-model="company.company_setting.invoice_shipping_method"
            :options="invoiceShippingMethods"
          />
        </ListColumn>
        <ListColumn header="請求期日">
          <Input
            type="text"
            id="invoice_due_date"
            v-model="company.company_setting.invoice_due_date"
          />
        </ListColumn>
        <ListColumn header="振込期日">
          <Input
            type="text"
            id="transfer_due_date"
            v-model="company.company_setting.transfer_due_date"
          />
        </ListColumn>
        <ListColumn header="支払い方法">
          <Select
            v-model="company.company_setting.payment_method"
            :options="paymentMethods"
          />
        </ListColumn>
        <ListColumn header="freee取引ID">
          <Input
            type="text"
            id="freee_account_id"
            v-model="company.company_setting.freee_account_id"
          />
        </ListColumn>
      </ListRowWrap>

      <ListRowWrap class="list-row-wrap list-row-mt-n" :vertical="true">
        <ListColumn header="社内メモ">
          <Textarea
            id="memo"
            v-model="company.company_setting.memo"
            placeholder="社内メモ"
            rows="5"
          />
        </ListColumn>
      </ListRowWrap>

      <TitleText text="Co-Growth社内担当者" with-icon>
        <IconPerson class="icon" />
      </TitleText>

      <ListRowWrap class="list-row-wrap" :vertical="!isPcView">
        <ListColumn header="経理担当">
          <Select
            v-model="company.company_setting.accounting_operator_id"
            :options="operatorsOptions"
          />
        </ListColumn>
        <ListColumn header="取引担当">
          <Select
            v-model="company.company_setting.cs_operator_id"
            :options="operatorsOptions"
          />
        </ListColumn>
      </ListRowWrap>
    </div>

    <CompanyCardFooter :update-account="updateAccount" />
  </CardWrap>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useIsPcView } from "@/hooks/use-is-pc";
import CardWrap from "@/components/common/CardWrap.vue";
import TitleText from "@/components/common/TitleText.vue";
import CompanyCardHeader from "./CompanyCardHeader.vue";
import CompanyCardFooter from "./CompanyCardFooter.vue";
import ListRowWrap from "@/components/common/list/ListRowWrap.vue";
import ListColumn from "@/components/common/list/ListColumn.vue";
import Input from "@/components/common/form/Input.vue";
import Textarea from "@/components/common/form/Textarea.vue";
import Select from "@/components/common/form/Select.vue";
import Loading from "@/components/common/Loading.vue";

import IconContract from "@/assets/svgs/icon_contract.svg";
import IconInvoice from "@/assets/svgs/icon_invoice.svg";
import IconPerson from "@/assets/svgs/icon_person.svg";
import IconCalendar from "@/assets/svgs/icon_calendar.svg";

import companyApi from "@/api/company";
import operatorApi from "@/api/operator";
import paymentMethodApi from "@/api/payment-method";
import invoiceShippingMethodApi from "@/api/invoice-shipping-method";
import { ICompany } from "@/models/company";
import { IOperator } from "@/models/operator";
import { ISelectOption } from "@/models/select-option";

const isPcView = useIsPcView();
const router = useRouter();
const company = ref<ICompany>({} as ICompany);
const operators = ref<IOperator[]>([]);
const paymentMethods = ref<ISelectOption[]>([]);
const invoiceShippingMethods = ref<ISelectOption[]>([]);
const isLoaded = ref<boolean>(false);

onMounted(async () => {
  await Promise.all([
    companyApi
      .getCompany(router.currentRoute.value.params.id as string)
      .then((res: ICompany) => {
        company.value = res;
      }),
    operatorApi.getOperators().then((res: IOperator[]) => {
      operators.value = res;
    }),
    paymentMethodApi.getPaymentMethods().then((res: ISelectOption[]) => {
      paymentMethods.value = res;
    }),
    invoiceShippingMethodApi
      .getInvoiceShippingMethods()
      .then((res: ISelectOption[]) => {
        invoiceShippingMethods.value = res;
      }),
  ]).then(() => {
    isLoaded.value = true;
  });
});

const operatorsOptions = computed((): ISelectOption[] => {
  return operators.value.map((operator) => {
    return {
      label: operator.name,
      value: operator.id.toString(),
    };
  });
});

const isUpdating = ref<boolean>(false);
const updateAccount = async () => {
  isUpdating.value = true;
  await companyApi
    .updateCompany(company.value)
    .then((res: ICompany) => {
      company.value = res;
    })
    .finally(() => {
      router.go(-1);
      isUpdating.value = false;
    });
};
</script>

<style lang="scss" scoped>
.account-edit-form {
  padding: $gap 0 0;
}

.list-row-mt-n {
  margin-top: -20px;
}

.list-row-wrap {
  margin-bottom: calc(2 * $gap);
}
</style>
