<template>
  <header id="app_header">
    <img class="header_logo_img" src="@/assets/images/logo.png" alt="logo" />
    <h1 class="header_logo_title">請求管理</h1>
    <button
      class="header_logout_button"
      @click="logout"
      v-if="store.state.isLoggedIn"
    >
      ログアウト
    </button>
  </header>
</template>

<script setup lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const logout = () => {
  store.dispatch("signout").then(() => {
    router.replace({ name: "signin" });
  });
};
</script>

<style lang="scss" scoped>
#app_header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  position: relative;
}

.header_logo_img {
  display: inline-block;
}

.header_logo_title {
  font-size: 1.5rem;
  font-weight: bolder;
  display: inline-block;
  margin: 1rem;
}

.header_logout_button {
  bottom: 0;
  color: $text-color-light;
  cursor: pointer;
  font-size: 0.9rem;
  position: absolute;
  right: 1rem;
  top: 0;
}

.sp {
  #app_header {
    border-bottom: 1px solid $border-color-dark;
  }
  .header_logo_img {
    height: 20px;
  }

  .header_logo_title {
    font-size: 1rem;
    margin-top: $gap-half;
    margin-bottom: $gap-half;
    line-height: 1.25;
  }
}
</style>
