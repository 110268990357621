<template>
  <div class="card_header">
    <div class="account_info">
      <p
        class="account_name"
        @click="() => onClickAccountName(companyInvoice.id)"
      >
        {{ companyInvoice.company_name }}
        <Icon
          icon-name="icon_edit"
          icon-circle
          class-name="account_edit_icon"
        />
      </p>
      <h2 class="invoice_month">
        <span>{{ companyInvoice.year }}</span>
        <span class="small">年</span>
        <span>{{ companyInvoice.month }}</span>
        <span class="small">月</span>
        <span>請求分</span>
      </h2>
    </div>

    <div class="remain_count">
      <span>残り</span>
      <span class="count">{{ remainCompanyInvoices.length + 1 }}</span>
      <span>件</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import Icon from "@/components/common/Icon.vue";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
  remainCompanyInvoices: ICompanyInvoice[];
}
defineProps<IProps>();

const router = useRouter();
const onClickAccountName = (companyId: number) => {
  router.push({ name: "cs-company-edit", params: { id: companyId } });
};
</script>

<style lang="scss" scoped>
.card_header {
  display: flex;
  align-items: center;
  margin: 0 calc(-1 * $gap-half);
  padding: 0 $gap-half $gap-half;
  border-bottom: 1px solid $border-color;
}

.account_info {
  margin-right: auto;
}

.account_name {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bolder;
  cursor: pointer;
  color: $orange;
  border-bottom: 1px solid $orange;
  margin-bottom: 0.5rem;
}

.account_edit_icon {
  margin-left: 0.5rem;
}

.invoice_month {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: bolder;
  text-align: left;

  span.small {
    font-size: 1rem;
  }
}

.remain_count {
  font-weight: bolder;
  text-align: right;

  .count {
    font-size: 1.25rem;
  }
}
</style>
