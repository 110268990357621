<template>
  <BaseModal
    header-text="取引担当者を変更"
    :visible="visible"
    :on-close="onClose"
  >
    <label>取引担当者</label>
    <Loading v-if="isLoading" :is-loading="isLoading" is-fixed />
    <Select
      v-model="operatorId"
      :options="operatorOptions"
      placeholder="取引担当者を選択してください"
    />

    <template #footer>
      <Btn
        text="キャンセル"
        btn-type="btn-gray"
        :on-click-event="onClose"
        class="cancel-btn"
      />
      <Btn
        text="決定"
        btn-type="btn-primary"
        :on-click-event="onSubmit"
        class="submit-btn"
        :disabled="isLoading"
      />
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, defineProps, withDefaults, watch, computed } from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import Select from "@/components/common/form/Select.vue";
import Btn from "@/components/common/Btn.vue";
import Loading from "@/components/common/Loading.vue";
import CompanyInvoiceApi from "@/api/company-invoice";
import OperatorApi from "@/api/operator";
import { ICompanyInvoice } from "@/models/company-invoice";
import { IOperator } from "@/models/operator";

interface IProps {
  companyInvoice: ICompanyInvoice;
  visible: boolean;
  headerText?: string;
  onClose?: () => void;
}

const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  onClose: () => {
    return;
  },
});

const isLoading = ref(false);
const operators = ref<IOperator[]>([]);

watch(
  () => props.visible,
  async (visible) => {
    if (visible) {
      isLoading.value = true;
      await OperatorApi.getOperators().then((res) => {
        operators.value = res;
      });
      isLoading.value = false;
    } else {
      operators.value = [];
    }
  }
);

const operatorId = ref(props.companyInvoice.cs_operator_id);

const operatorOptions = computed(() => {
  return operators.value.map((operator) => {
    return {
      value: operator.id,
      label: operator.name,
    };
  });
});

const onSubmit = async () => {
  if (isLoading.value) return;

  await CompanyInvoiceApi.updateCsOperator(
    props.companyInvoice.id,
    operatorId.value
  ).then(() => {
    // reload する
    location.reload();
  });
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: $gap-half;
}
.cancel-btn {
  margin-right: $gap-half;
}

.sp {
  label {
    font-size: 0.875rem;
  }

  .cancel-btn,
  .submit-btn {
    min-width: auto;
    width: 100%;
  }
}
</style>
