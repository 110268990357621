<template>
  <div class="textarea-wrap">
    <textarea
      class="textarea"
      :value="modelValue"
      @input="updateValue"
      v-bind="$attrs"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

interface IProps {
  modelValue: string;
}

defineProps<IProps>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
};
</script>

<style lang="scss" scoped>
.textarea-wrap {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: $radius;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
  position: relative;

  .textarea {
    width: 100%;
    padding: 0.5rem;
    border: none;
    line-height: 1.5;
    min-height: 100px;
    font-weight: normal;

    &:disabled {
      background: #fafafa;
      cursor: not-allowed;
    }

    &::placeholder {
      color: $text-color-placeholder;
      font-weight: normal;
    }
  }
}

.sp {
  .textarea-wrap {
    font-size: 0.875rem;
  }
}
</style>
