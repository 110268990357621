<template>
  <div class="card-invoice-info-wrap">
    <TitleText text="請求書記載情報" with-icon>
      <IconInvoice class="icon" />
    </TitleText>

    <ListRow :columns="columns" />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import TitleText from "@/components/common/TitleText.vue";
import ListRow from "@/components/common/list/ListRow.vue";
import IconInvoice from "@/assets/svgs/icon_invoice.svg";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}
const props = defineProps<IProps>();

const columns = [
  {
    header: "請求先（宛名）",
    text: props.companyInvoice.invoice_address || '-',
  },
  {
    header: "請求先（To）",
    text: props.companyInvoice.invoice_email || '-',
  },
  {
    header: "請求期日",
    text: props.companyInvoice.labels.invoice_due_date || "-"
  },
  {
    header: "振込期日",
    text: props.companyInvoice.labels.transfer_due_date || "-"
  },
  {
    header: "送付方法",
    text: props.companyInvoice.labels.invoice_shipping_method || "-"
  },
];
</script>

<style lang="scss" scoped>
.card-invoice-info-wrap {
  padding: $gap 0;
}
</style>
