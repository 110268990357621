<template>
  <CardWrap class="signin-card">
    <form @submit.prevent>
      <Input
        type="email"
        id="email"
        v-model="email"
        placeholder="メールアドレス"
        class="email-input"
      />
      <Input
        type="password"
        id="password"
        v-model="password"
        placeholder="パスワード"
        class="password-input"
      />
      <Btn class="full-width" :on-click-event="onSubmit">ログイン</Btn>
    </form>
  </CardWrap>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { routingNameByRole } from "@/router/auth-guard";
import CardWrap from "@/components/common/CardWrap.vue";
import Btn from "@/components/common/Btn.vue";
import Input from "@/components/common/form/Input.vue";

const store = useStore();
const router = useRouter();

const email = ref("");
const password = ref("");

const onSubmit = async () => {
  await store
    .dispatch("signin", {
      email: email.value,
      password: password.value,
    })
    .then((result) => {
      if (result) {
        router.replace({ name: routingNameByRole(store.state.role) });
      }
    });
};
</script>

<style lang="scss" scoped>
.signin-card {
  width: 450px;
  margin: 0 auto;
  text-align: center;

  .email-input {
    text-align: left;
    margin-bottom: $gap-half;
  }

  .password-input {
    text-align: left;
    margin-bottom: $gap;
  }

  .full-width {
    width: 100%;
  }
}
</style>
