<template>
  <div class="input-wrap" :class="{ 'with-icon': withIcon }">
    <input
      class="input"
      :value="modelValue"
      @input="updateValue"
      v-bind="$attrs"
    />
    <div v-if="withIcon" class="input-icon">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from "vue";

interface IProps {
  modelValue: string | number;
  withIcon?: boolean;
}

withDefaults(defineProps<IProps>(), {
  withIcon: false,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
};
</script>

<style lang="scss" scoped>
.input-wrap {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: $radius;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
  position: relative;
  font-weight: normal;

  .input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    line-height: 1.5;

    &:disabled {
      background: #fafafa;
      cursor: not-allowed;
    }
  }


  &.with-icon {
    .input {
      padding-right: 30px;
    }

    .input-icon {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.sp {
  .input-wrap {
    font-size: 0.875rem;
  }
}
</style>
