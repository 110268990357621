import ApiClientWithCredentials from "@/lib/api-client";
import Settings from "@/constants/settings";
import { AxiosResponse } from "axios";
import { IOperator } from "@/models/operator";

export default {
  async getOperators(): Promise<IOperator[]> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/operators`
    ).then((response: AxiosResponse<IOperator[]>) => {
      return response.data as IOperator[];
    });
  },
};
