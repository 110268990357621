type ISettings = {
  API_V1: string;
};

const baseUrl = process.env.VUE_APP_API_URL;
const Settings: ISettings = {
  API_V1: `${baseUrl}/api/v1`,
};

export default Settings;
