import ApiClientWithCredentials from "@/lib/api-client";
import Settings from "@/constants/settings";
import { AxiosResponse } from "axios";
import { ICompanyInvoice } from "@/models/company-invoice";
import { ISortDirection } from "@/models/sort-direction";

export interface IGetCompanyInvoicesParams {
  year?: string;
  month?: string;
  sortCondition?: string;
  sortDirection?: ISortDirection;
}

const CompanyInvoiceApi = {
  // ==================== Accounting ====================
  async getCompanyInvoices({
    year,
    month,
    sortCondition,
    sortDirection,
  }: IGetCompanyInvoicesParams = {}): Promise<ICompanyInvoice[]> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/accounting/company_invoices`,
      {
        params: {
          year,
          month,
          sort_condition: sortCondition,
          sort_direction: sortDirection,
        },
      }
    ).then((response: AxiosResponse<ICompanyInvoice[]>) => {
      return response.data;
    });
  },

  async updateStatus(
    companyInvoiceId: number,
    status: string
  ): Promise<ICompanyInvoice> {
    return await ApiClientWithCredentials.put(
      `${Settings.API_V1}/accounting/company_invoice_statuses/${companyInvoiceId}`,
      {
        company_invoice: {
          status: status,
        },
      }
    ).then((response: AxiosResponse<ICompanyInvoice>) => {
      return response.data as ICompanyInvoice;
    });
  },


  // ==================== CS ====================
  async getCsCompanyInvoices(): Promise<ICompanyInvoice[]> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/cs/company_invoices`
    ).then((response: AxiosResponse<ICompanyInvoice[]>) => {
      return response.data;
    });
  },

  async updateCsOperator(
    companyInvoiceId: number,
    csOperatorId: number
  ): Promise<ICompanyInvoice> {
    return await ApiClientWithCredentials.put(
      `${Settings.API_V1}/cs/company_invoice_cs_operators/${companyInvoiceId}`,
      {
        company_invoice: {
          cs_operator_id: csOperatorId,
        },
      }
    ).then((response: AxiosResponse<ICompanyInvoice>) => {
      return response.data as ICompanyInvoice;
    });
  },

  async updateCompanyInvoiceStatusToBilled(
    companyInvoiceId: number
  ): Promise<ICompanyInvoice> {
    return await ApiClientWithCredentials.put(
      `${Settings.API_V1}/cs/billed_company_invoices/${companyInvoiceId}`
    ).then((response: AxiosResponse<ICompanyInvoice>) => {
      return response.data as ICompanyInvoice;
    });
  },

  async updateCompanyInvoiceStatusToIrregular(
    companyInvoiceId: number,
    irregularReason: string,
    irregularReasonComment: string
  ): Promise<ICompanyInvoice> {
    return await ApiClientWithCredentials.put(
      `${Settings.API_V1}/cs/irregular_company_invoices/${companyInvoiceId}`,
      {
        company_invoice: {
          irregular_reason: irregularReason,
          irregular_reason_comment: irregularReasonComment,
        },
      }
    ).then((response: AxiosResponse<ICompanyInvoice>) => {
      return response.data as ICompanyInvoice;
    });
  },
};

export default CompanyInvoiceApi;
