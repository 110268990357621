import ApiClientWithCredentials from "@/lib/api-client";
import Settings from "@/constants/settings";
import { AxiosResponse } from "axios";
import { ICompanySetting } from "@/models/company-setting";
import { ISortDirection } from "@/models/sort-direction";

export interface IGetCompanySettingsParams {
  sortCondition?: string;
  sortDirection?: ISortDirection;
}

const CompanySettingApi = {
  // ==================== Accounting ====================
  async getCompanySettings({
    sortCondition,
    sortDirection,
  }: IGetCompanySettingsParams = {}): Promise<ICompanySetting[]> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/accounting/company_settings`,
      {
        params: {
          sort_condition: sortCondition,
          sort_direction: sortDirection,
        },
      }
    ).then((response: AxiosResponse<ICompanySetting[]>) => {
      return response.data;
    });
  }
}

export default CompanySettingApi;