<template>
  <CardWrap class="card-wrap">
    <CardHeader
      :company-invoice="companyInvoice"
      :remain-company-invoices="remainCompanyInvoices"
    />
    <CardTab :mode="state.mode" :change-mode="changeMode" />
    <CardContractInfo
      v-if="state.mode === 'contractInfo'"
      :company-invoice="companyInvoice"
    />
    <CardInvoiceInfo
      v-if="state.mode === 'invoiceInfo'"
      :company-invoice="companyInvoice"
    />
    <CardInvoiceTable
      v-if="state.mode === 'invoiceInfo'"
      :company-invoice="companyInvoice"
    />
  </CardWrap>
  <CardFooter :company-invoice="companyInvoice" />
</template>

<script setup lang="ts">
import { defineProps, reactive } from "vue";
import CardWrap from "@/components/common/CardWrap.vue";
import CardHeader from "./CardHeader.vue";
import CardTab from "./CardTab.vue";
import CardContractInfo from "./CardContractInfo.vue";
import CardInvoiceInfo from "./CardInvoiceInfo.vue";
import CardInvoiceTable from "./CardInvoiceTable.vue";
import CardFooter from "./CardFooter.vue";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
  remainCompanyInvoices: ICompanyInvoice[];
}
defineProps<IProps>();

export type IDisplayContent = "contractInfo" | "invoiceInfo";

const state: {
  mode: IDisplayContent;
} = reactive({
  mode: "contractInfo",
});

const changeMode = (mode: IDisplayContent) => {
  console.log(mode);
  state.mode = mode;
};
</script>

<style lang="scss" scoped>
.card-wrap {
  margin-bottom: $gap-half;
}
</style>
