<template>
  <div v-if="iconCircle" :class="className" class="icon_circle">
    <img class="icon" :src="img" :alt="iconName" />
  </div>

  <img v-else class="icon" :class="className" :src="img" :alt="iconName" />
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";

interface IProps {
  iconName: string;
  iconCircle: boolean;
  className?: string;
}

const props = defineProps<IProps>();

const img = ref();

// NOTE: png only
img.value = require(`@/assets/images/${props.iconName}.png`);
</script>

<style lang="scss" scoped>
.icon_circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $gray;
}

.icon {
  vertical-align: top;
}
</style>
