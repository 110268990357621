<template>
  <div class="invoice-table__wrap">
    <table class="invoice-table">
      <thead>
        <tr>
          <th>概要</th>
          <th>数量</th>
          <th>単価</th>
          <th>金額</th>
        </tr>
      </thead>

      <tbody class="invoice-detail-row__wrap">
        <tr v-for="detail in companyInvoice.company_invoice_details" :key="detail.id">
          <td>{{ detail.title }}</td>
          <td class="invoice-detail-row__number">
            {{ detail.quantity.toLocaleString() }}
          </td>
          <td class="invoice-detail-row__number">
            {{ detail.unit_price.toLocaleString() }}
          </td>
          <td class="invoice-detail-row__number">
            ￥{{ calcDetailPrice(detail.quantity, detail.unit_price).toLocaleString() }}
          </td>
        </tr>
      </tbody>

      <tbody class="row-space">
        <tr>
          <td colspan="4"></td>
        </tr>
      </tbody>

      <tbody class="invoice-total-row__wrap">
        <tr>
          <td class="no-border" colspan="2"></td>
          <td>小計</td>
          <td>￥{{ subtotal.toLocaleString() }}</td>
        </tr>
        <tr>
          <td class="no-border" colspan="2"></td>
          <td>消費税</td>
          <td>￥{{ consumptionTax.toLocaleString() }}</td>
        </tr>
        <tr>
          <td class="no-border" colspan="2"></td>
          <td>合計</td>
          <td class="font-bolder">￥{{ total.toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}

const props = defineProps<IProps>();

const calcDetailPrice = (quantity: number, unitPrice: number) => {
  return quantity * unitPrice;
};

// 小計
const subtotal = computed(() => {
  return props.companyInvoice.company_invoice_details.reduce((acc, detail) => {
    return acc + calcDetailPrice(detail.quantity, detail.unit_price);
  }, 0);
});

const consumptionTax = computed(() => {
  return subtotal.value * 0.1;
});

const total = computed(() => {
  return subtotal.value + consumptionTax.value;
});
</script>

<style lang="scss" scoped>
.invoice-table {
  width: 100%;
  font-size: 0.875rem;

  thead {
    background: $border-color;
    border: 1px solid $border-color-dark;

    th {
      padding: 0.75rem;
      font-weight: bolder;
      text-align: center;
    }
  }

  tbody {
    td {
      padding: 0.75rem;
    }
  }

  tbody.invoice-detail-row__wrap {
    text-align: left;

    tr {
      border: 1px solid $border-color-dark;
    }

    .invoice-detail-row__number {
      text-align: right;
    }
  }
}

tbody.invoice-total-row__wrap {
  td:not(.no-border) {
    border-top: 1px solid $border-color-dark;
    border-bottom: 1px solid $border-color-dark;

    &:nth-child(2) {
      font-weight: bolder;
      border-left: 1px solid $border-color-dark;
    }

    &:last-child {
      text-align: right;
      border-right: 1px solid $border-color-dark;
    }
  }
}
</style>
