<template>
  <div class="card_header">
    <div class="account_info">
      <p
        class="account_name"
        @click="() => onClickAccountName(companyInvoice.company_id)"
      >
        {{ companyInvoice.company_name }}
        <Icon
          icon-name="icon_edit"
          icon-circle
          class-name="account_edit_icon"
        />
      </p>
      <h2 class="invoice_month">
        <span>{{ companyInvoice.year }}</span>
        <span class="small">年</span>
        <span>{{ companyInvoice.month }}</span>
        <span class="small">月</span>
        <span>請求分</span>
      </h2>
    </div>

    <div class="remain_count">
      <span>残り</span>
      <span class="count">{{ remainCompanyInvoices.length + 1 }}</span>
      <span>件</span>
    </div>

    <Btn
      text="取引担当者を変更"
      :on-click-event="onClickChangeOperator"
      btn-type="btn-gray"
    />

    <Btn btn-type="btn-gray" :on-click-event="onClickIrregularChangeOperator">
      イレギュラーのため変更
      <br />
      <span class="small-text">（初月含む）</span>
    </Btn>

    <Btn :on-click-event="onClickOrderInvoice" :disabled="isSubmitting">
      この内容で請求書を発行依頼
      <span v-if="countDown > 0">({{ countDown }})</span>
    </Btn>
  </div>
  <ChangeOperatorModal
    :visible="state.visibleOperatorModal"
    :company-invoice="companyInvoice"
    :on-close="closeOperatorModal"
  />

  <IrregularUpdateModal
    :company-invoice="companyInvoice"
    :visible="state.visibleIrregularModal"
    :on-close="closeIrregularModal"
  />
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, defineProps } from "vue";
import { useRouter } from "vue-router";
import Btn from "@/components/common/Btn.vue";
import Icon from "@/components/common/Icon.vue";
import ChangeOperatorModal from "../common/ChangeOperatorModal.vue";
import IrregularUpdateModal from "../common/IrregularUpdateModal.vue";
import { ICompanyInvoice } from "@/models/company-invoice";
import CompanyInvoiceApi from "@/api/company-invoice";

const router = useRouter();

let isSubmitting = ref(true);
let countDown = ref(5)

onMounted(() => {
  const interval = setInterval(() => {
    countDown.value--
    if (countDown.value === 0) {
      clearInterval(interval)
      isSubmitting.value = false
    }
  }, 1000)
})

interface IProps {
  companyInvoice: ICompanyInvoice;
  remainCompanyInvoices: ICompanyInvoice[];
}
const props = defineProps<IProps>();

const state = reactive({
  visibleOperatorModal: false,
  visibleIrregularModal: false,
});

const closeOperatorModal = () => {
  state.visibleOperatorModal = false;
};

const openOperatorModal = () => {
  closeIrregularModal();
  state.visibleOperatorModal = true;
};

const closeIrregularModal = () => {
  state.visibleIrregularModal = false;
};

const openIrregularModal = () => {
  closeOperatorModal();
  state.visibleIrregularModal = true;
};

const onClickAccountName = (companyId: number) => {
  router.push({ name: "cs-company-edit", params: { id: companyId } });
};

const onClickChangeOperator = () => {
  console.log("onClickChangeOperator");
  openOperatorModal();
};

const onClickIrregularChangeOperator = () => {
  console.log("onClickIrregularChangeOperator");
  openIrregularModal();
};

const onClickOrderInvoice = async () => {
  isSubmitting.value = true
  await CompanyInvoiceApi.updateCompanyInvoiceStatusToBilled(
    props.companyInvoice.id
  ).then(() => {
    isSubmitting.value = false;
    // reload する
    location.reload();
  }, () =>{
    isSubmitting.value = false;
  });
};
</script>

<style lang="scss" scoped>
.card_header {
  display: flex;
  align-items: center;
  margin: 0 calc(-1 * $gap);
  padding: 0 $gap $gap;
  border-bottom: 1px solid $border-color;
}

.account_info {
  margin-right: auto;
}

.account_name {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bolder;
  cursor: pointer;
  color: $orange;
  border-bottom: 1px solid $orange;
  margin-bottom: 0.5rem;
}

.account_edit_icon {
  margin-left: 0.5rem;
}

.invoice_month {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: bolder;
  text-align: left;

  span.small {
    font-size: 1rem;
  }
}

.remain_count {
  font-weight: bolder;
  margin-right: calc(2 * $gap);

  .count {
    font-size: 1.25rem;
  }
}

.btn + .btn {
  margin-left: $gap;
}
</style>
