<template>
  <Loading v-if="isInitialLoading" :is-loading="isInitialLoading" is-fixed />
  <template v-else>
    <EmptyState v-if="companyInvoices.length === 0" />
    <template v-else>
      <CardPC
        v-if="isPcView"
        :company-invoice="displayInvoice"
        :remain-company-invoices="remainInvoices"
      />
      <CardSp
        v-else
        :company-invoice="displayInvoice"
        :remain-company-invoices="remainInvoices"
      />
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useIsPcView } from "@/hooks/use-is-pc";
import CardPC from "./card/pc/Card.vue";
import CardSp from "./card/sp/Card.vue";
import EmptyState from "./card/common/EmptyState.vue";
import Loading from "@/components/common/Loading.vue";
import CompanyInvoiceApi from "@/api/company-invoice";
import { ICompanyInvoice } from "@/models/company-invoice";

const isPcView = useIsPcView();

const isInitialLoading = ref(true);
const companyInvoices = ref<ICompanyInvoice[]>([]);

onMounted(async () => {
  await CompanyInvoiceApi.getCsCompanyInvoices().then((res) => {
    console.log(res);
    companyInvoices.value = res;
    isInitialLoading.value = false;
  });
});

const displayInvoice = computed(() => {
  console.log(companyInvoices.value);
  return companyInvoices.value[0];
});

const remainInvoices = computed(() => {
  return companyInvoices.value.slice(1);
});
</script>

<style lang="scss" scoped></style>
