<template>
  <div class="invoice-table__wrap">
    <table class="invoice-table">
      <thead>
        <tr>
          <th colspan="2">概要/金額</th>
        </tr>
      </thead>

      <tbody class="invoice-detail-row__wrap">
        <template v-for="detail in companyInvoice.company_invoice_details" :key="detail.id">
          <tr>
            <td colspan="2" class="invoice-detail-row__item">
              {{ detail.title }}
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <table class="invoice-detail-row__item--table">
                <thead>
                  <th>数量</th>
                  <th>単価</th>
                  <th>金額</th>
                </thead>
                <tbody>
                  <td>
                    {{ detail.quantity.toLocaleString() }}
                  </td>
                  <td>
                    {{ detail.unit_price.toLocaleString() }}
                  </td>
                  <td> ￥{{ calcDetailPrice(detail.quantity, detail.unit_price).toLocaleString() }}</td>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>

      <tbody class="row-space">
        <tr>
          <td></td>
        </tr>
      </tbody>

      <tbody class="invoice-total-row__wrap">
        <tr>
          <td>小計</td>
          <td>￥{{ subtotal.toLocaleString() }}</td>
        </tr>
        <tr>
          <td>消費税</td>
          <td>￥{{ consumptionTax.toLocaleString() }}</td>
        </tr>
        <tr>
          <td>合計</td>
          <td class="font-bolder">￥{{ total.toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}
const props = defineProps<IProps>();

const calcDetailPrice = (quantity: number, unitPrice: number) => {
  return quantity * unitPrice;
};

// 小計
const subtotal = computed(() => {
  return props.companyInvoice.company_invoice_details.reduce((acc, detail) => {
    return acc + calcDetailPrice(detail.quantity, detail.unit_price);
  }, 0);
});

const consumptionTax = computed(() => {
  return subtotal.value * 0.1;
});

const total = computed(() => {
  return subtotal.value + consumptionTax.value;
});
</script>

<style lang="scss" scoped>
.invoice-table {
  width: 100%;
  font-size: 0.875rem;
  margin-top: $gap;

  & > thead {
    background: $border-color;
    border: 1px solid $border-color-dark;

    th {
      padding: 0.75rem;
      font-weight: bolder;
    }
  }

  tbody.invoice-detail-row__wrap {
    text-align: left;
    border-left: 1px solid $border-color-dark;
    border-right: 1px solid $border-color-dark;

    .invoice-detail-row__item {
      padding: $gap-half;
    }
  }

  .invoice-detail-row__item--table {
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid $border-color-dark;

    th {
      font-size: 0.625rem;
      text-align: left;
      padding: 0 $gap-half;
    }

    td {
      text-align: right;
      padding: 0 $gap-half $gap-half;
    }
  }
}

.row-space {
  tr td {
    height: $gap-half;
    padding: 0;
  }
}
tbody.invoice-total-row__wrap {
  tr {
    border: 1px solid $border-color-dark;
  }
  td {
    padding: $gap-half;

    &:last-child {
      text-align: right;
      border-right: 1px solid $border-color-dark;
    }
  }
}
</style>
