<template>
  <div class="card-contract-info-wrap">
    <TitleText text="契約情報" with-icon>
      <IconContract class="icon" />
    </TitleText>

    <ListRow :columns="columns" />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import TitleText from "@/components/common/TitleText.vue";
import ListRow from "@/components/common/list/ListRow.vue";
import IconContract from "@/assets/svgs/icon_contract.svg";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}
const props = defineProps<IProps>();

const columns = [
  {
    header: "契約プラン",
    text: props.companyInvoice.labels.contract_plan,
  },
  {
    header: "年次契約",
    text: `${props.companyInvoice.annually_contract_count.toLocaleString()} ID`,
  },
  {
    header: "年次契約における備考",
    text: props.companyInvoice.annually_contract_memo || '-',
  },
  {
    header: "月次契約",
    text: `${props.companyInvoice.monthly_contract_count.toLocaleString()} ID`,
  },
  {
    header: "月次契約における備考",
    text: props.companyInvoice.monthly_contract_memo || '-',
  },
];
</script>

<style lang="scss" scoped>
.card-contract-info-wrap {
  padding: $gap 0;
  border-bottom: 1px solid $border-color;
}
</style>
