<template>
  <div class="card-tab">
    <div class="card-tab__wrap">
      <div
        class="card-tab__item"
        :class="{
          'card-tab__item--active': mode === 'contractInfo',
        }"
        @click="changeMode('contractInfo')"
      >
        <IconContractOrange
          v-if="isSelectedTab('contractInfo')"
          class="icon-svg"
        />
        <IconContract v-else class="icon-svg" />
        契約情報
      </div>
      <div
        class="card-tab__item"
        :class="{ 'card-tab__item--active': mode === 'invoiceInfo' }"
        @click="changeMode('invoiceInfo')"
      >
        <IconInvoiceOrange
          v-if="isSelectedTab('invoiceInfo')"
          class="icon-svg"
        />
        <IconInvoice v-else class="icon-svg" />
        請求書記載情報
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { IDisplayContent } from "./Card.vue";
import IconContract from "@/assets/svgs/icon_contract.svg";
import IconContractOrange from "@/assets/svgs/icon_contract_orange.svg";
import IconInvoice from "@/assets/svgs/icon_invoice.svg";
import IconInvoiceOrange from "@/assets/svgs/icon_invoice_orange.svg";

interface IProps {
  mode: IDisplayContent;
  changeMode: (mode: IDisplayContent) => void;
}

const props = defineProps<IProps>();

const isSelectedTab = (mode: IDisplayContent) => {
  return mode === props.mode;
};
</script>

<style lang="scss" scoped>
.card-tab {
  margin-right: calc(-1 * $gap-half);
  margin-left: calc(-1 * $gap-half);
  border-bottom: 1px solid $border-color;
  margin-bottom: $gap-half;

  &__wrap {
    display: flex;
    justify-content: space-between;
    padding: 0 $gap-half 0;

    & > * {
      width: 50%;
    }
  }

  &__item {
    padding: 0.5rem 0;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
    color: $text-color-light;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      border-bottom: 4px solid $orange;
      color: $orange;
    }

    .icon-svg {
      margin-right: $gap-half;
    }
  }
}
</style>
