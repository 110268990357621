<template>
  <div class="select-wrap">
    <select
      class="select"
      :value="modelValue"
      @change="updateValue"
      v-bind="$attrs"
    >
      <option
        v-for="option in options"
        :key="options.indexOf(option)"
        :selected="option.value === modelValue"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <IconArrowBottom class="select-icon" />
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import IconArrowBottom from "@/assets/svgs/icon_arrow_bottom.svg";

interface IProps {
  modelValue: string | boolean | number;
  options: { value: string | number; label: string }[];
}

defineProps<IProps>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
};
</script>

<style lang="scss" scoped>
.select-wrap {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: $radius;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: normal;

  .select {
    width: 100%;
    padding: 0.5rem;
    border: none;
    line-height: 1.5;
    appearance: none;
    background-color: transparent;

    &:disabled {
      background: #fafafa;
      cursor: not-allowed;
    }
  }

  .select-icon {
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 1.75px);
  }
}

.sp {
  .select-wrap {
    font-size: 0.875rem;
  }
}
</style>
