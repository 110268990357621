import type { Router, RouteLocationNormalized } from "vue-router";
import { useStore } from "vuex";
import { IOperatorRole } from "@/models/operator";

export const authenticationGuard = (router: Router) => {
  router.beforeEach(async (to) => {
    const store = useStore();

    // storeにログイン情報があれば認証済みとみなす
    // ない場合は cookie を元に認証情報を取得する
    let isLoggedIn = false;
    if (!store.state.isLoggedIn) {
      await store.dispatch("checkAuth");

      isLoggedIn = store.state.isLoggedIn;
    } else {
      isLoggedIn = true;
    }

    // 既に認証されていれば権限を確認する
    if (isLoggedIn) {
      return askForPermission(store.state.role, to);
    }
    // 未認証状態で遷移を許可するルート
    else if (["signin"].includes(to.name as string)) {
      return true;
    }
    // 認証後のリダイレクトで使う。必要に応じてパラメーターも保存しておく。
    // routingStore.setRedirectFrom(to.name);
    else return { name: "signin" };
  });
};

// 権限(role)を確認する
const askForPermission = (role: IOperatorRole, to: RouteLocationNormalized) => {
  if (to.meta.requiresCsAuth && role !== "cs") {
    return { name: "signin" };
  }

  if (to.meta.requiresAccountingAuth && role !== "accounting") {
    return { name: "signin" };
  }

  if (to.meta.requiresNotAuth) {
    return { name: routingNameByRole(role) };
  }

  // 認証状態・権限を確認する必要が無い場合は遷移を許可する
  return true;
};

export const routingNameByRole = (role: IOperatorRole) => {
  switch (role) {
    case "cs":
      return "cs";
    case "accounting":
      return "accounting-invoices";
    default:
      return "signin";
  }
};
