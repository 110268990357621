import ApiClientWithCredentials from "@/lib/api-client";
import Settings from "@/constants/settings";
import { AxiosResponse } from "axios";
import { ICompany } from "@/models/company";

export default {
  async getCompany(id: string): Promise<ICompany> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/companies/${id}`
    ).then((response: AxiosResponse<ICompany>) => {
      return response.data as ICompany;
    });
  },
  async updateCompany(company: ICompany): Promise<ICompany> {
    return await ApiClientWithCredentials.put(
      `${Settings.API_V1}/companies/${company.id}`,
      {
        company: {
          name: company.name,
          contract_plan: company.contract_plan,

          company_setting_attributes: {
            id: company.company_setting.id,
            invoice_targetted: company.company_setting.invoice_targetted,
            annually_contract_count:
              company.company_setting.annually_contract_count,
            annually_contract_memo:
              company.company_setting.annually_contract_memo,
            annually_contract_expired_date:
              company.company_setting.annually_contract_expired_date,
            monthly_contract_count:
              company.company_setting.monthly_contract_count,
            monthly_contract_memo:
              company.company_setting.monthly_contract_memo,
            invoice_address: company.company_setting.invoice_address,
            invoice_email: company.company_setting.invoice_email,
            invoice_shipping_method:
              company.company_setting.invoice_shipping_method,
            invoice_due_date: company.company_setting.invoice_due_date,
            transfer_due_date: company.company_setting.transfer_due_date,
            payment_method: company.company_setting.payment_method,
            memo: company.company_setting.memo,
            freee_account_id: company.company_setting.freee_account_id,
            accounting_operator_id:
              company.company_setting.accounting_operator_id,
            cs_operator_id: company.company_setting.cs_operator_id,
          },
        },
      }
    ).then((response: AxiosResponse<ICompany>) => {
      return response.data as ICompany;
    });
  },
};
