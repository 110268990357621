<template>
  <div class="card-footer">
    <div class="half-size-btn-wrap">
      <Btn
        class="sp-btn"
        text="取引担当者を変更"
        :on-click-event="onClickChangeOperator"
        btn-type="btn-gray"
      />

      <Btn
        class="sp-btn"
        btn-type="btn-gray"
        :on-click-event="onClickIrregularChangeOperator"
      >
        イレギュラーのため変更
        <br />
        <span class="small-text">（初月含む）</span>
      </Btn>
    </div>
    <div>
      <Btn
        class="sp-btn full-width"
        text="この内容で請求書を発行依頼"
        :on-click-event="onClickOrderInvoice"
      />
    </div>
  </div>

  <ChangeOperatorModal
    :visible="state.visibleOperatorModal"
    :company-invoice="companyInvoice"
    :on-close="closeOperatorModal"
  />

  <IrregularUpdateModal
    :visible="state.visibleIrregularModal"
    :company-invoice="companyInvoice"
    :on-close="closeIrregularModal"
  />
</template>

<script setup lang="ts">
import { reactive, defineProps } from "vue";
import Btn from "@/components/common/Btn.vue";
import ChangeOperatorModal from "../common/ChangeOperatorModal.vue";
import IrregularUpdateModal from "../common/IrregularUpdateModal.vue";
import { ICompanyInvoice } from "@/models/company-invoice";
import CompanyInvoiceApi from "@/api/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}
const props = defineProps<IProps>();

const state = reactive({
  visibleOperatorModal: false,
  visibleIrregularModal: false,
});

const closeOperatorModal = () => {
  state.visibleOperatorModal = false;
};

const openOperatorModal = () => {
  closeIrregularModal();
  state.visibleOperatorModal = true;
};

const closeIrregularModal = () => {
  state.visibleIrregularModal = false;
};

const openIrregularModal = () => {
  closeOperatorModal();
  state.visibleIrregularModal = true;
};

const onClickChangeOperator = () => {
  console.log("onClickChangeOperator");
  openOperatorModal();
};

const onClickIrregularChangeOperator = () => {
  console.log("onClickIrregularChangeOperator");
  openIrregularModal();
};

const onClickOrderInvoice = async () => {
  await CompanyInvoiceApi.updateCompanyInvoiceStatusToBilled(
    props.companyInvoice.id
  ).then(() => {
    // reload する
    location.reload();
  });
};
</script>

<style lang="scss" scoped>
.card-footer {
  margin-left: calc(-1 * $gap-half);
  margin-right: calc(-1 * $gap-half);
  padding: $gap-half $gap-half;
  background: $white;

  .half-size-btn-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gap-half;
    margin-bottom: $gap-half;
  }

  .full-width {
    width: 100%;
  }

  .sp-btn {
    min-width: auto;
    font-size: 0.875rem;
    padding: 0;

    .small-text {
      font-size: 0.75rem;
    }
  }
}
</style>
