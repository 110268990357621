import ApiClientWithCredentials from "@/lib/api-client";
import Settings from "@/constants/settings";
import { AxiosResponse } from "axios";
import { ICompanyInvoicesMonth } from "@/models/company-invoices-month";

export default {
  async getCompanyInvoicesMonths(): Promise<ICompanyInvoicesMonth[]> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/accounting/company_invoices_months`
    ).then((response: AxiosResponse<ICompanyInvoicesMonth[]>) => {
      return response.data;
    });
  },
};
