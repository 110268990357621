<template>
  <div class="company-card-footer">
    <Btn class="action-btn" btn-type="btn-gray" :on-click-event="goBack">
      キャンセル
    </Btn>
    <Btn
      class="action-btn"
      btn-type="btn-primary"
      :on-click-event="updateAccount"
    >
      保存
    </Btn>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { defineProps } from "vue";
import Btn from "@/components/common/Btn.vue";

interface IProps {
  updateAccount: () => void;
}

defineProps<IProps>();

const router = useRouter();
const goBack = () => {
  router.go(-1);
};
</script>

<style lang="scss" scoped>
.company-card-footer {
  border-top: 1px solid $border-color-dark;
  padding: $gap $gap 0;
  margin: 0 calc(-1 * $gap);
  display: flex;
  justify-content: right;

  .action-btn {
    width: 300px;

    &:first-of-type {
      margin-right: $gap;
    }
  }
}
</style>
