<template>
  <BaseModal header-text="ステータスを変更" :visible="visible" :on-close="onClose">
    <label>ステータス</label>
    <Loading v-if="isLoading" :is-loading="isLoading" is-fixed />
    <Select v-model="status" :options="statusOptions" placeholder="ステータスを選択してください" />

    <template #footer>
      <Btn text="キャンセル" btn-type="btn-gray" :on-click-event="onClose" class="cancel-btn" />
      <Btn text="決定" btn-type="btn-primary" :on-click-event="onSubmit" class="submit-btn" :disabled="isLoading" />
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, withDefaults, watch } from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import Select from "@/components/common/form/Select.vue";
import Btn from "@/components/common/Btn.vue";
import Loading from "@/components/common/Loading.vue";
import CompanyInvoiceApi from "@/api/company-invoice";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
  visible: boolean;
  headerText?: string;
  onClose?: () => void;
}

const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  onClose: () => {
    return;
  },
});

const emits = defineEmits(["update:companyInvoice"]);

const isLoading = ref(false);

const status = ref(props.companyInvoice.status);

watch(
  () => props.companyInvoice,
  (newValue) => {
    status.value = newValue.status;
  }
);

const statusOptions =  [
  { label: '取引担当者確認中', value: 'checking' },
  { label: 'イレギュラー', value: 'irregular' },
  { label: 'freeeに発行済み', value: 'billed' },
];

const onSubmit = async () => {
  if (isLoading.value) return;

  isLoading.value = true;

  await CompanyInvoiceApi.updateStatus(
    props.companyInvoice.id,
    status.value
  ).then(() => {
    location.reload();
  });

  isLoading.value = false;

  props.onClose && props.onClose();
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: $gap-half;
}

.cancel-btn {
  margin-right: $gap-half;
}

.sp {
  label {
    font-size: 0.875rem;
  }

  .cancel-btn,
  .submit-btn {
    min-width: auto;
    width: 100%;
  }
}
</style>
