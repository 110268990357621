<template>
  <div class="company-card-header">
    <div @click="goBack" class="go-back-btn">
      <IconArrowLeft class="go-back-icon" />
      <span class="go-back-text">戻る</span>
    </div>
    <h1 class="page-header">アカウント詳細情報</h1>
  </div>
</template>

<script setup lang="ts">
import IconArrowLeft from "@/assets/svgs/icon_arrow_left.svg";
import { useRouter } from "vue-router";

const router = useRouter();
const goBack = () => {
  router.go(-1);
};
</script>

<style lang="scss" scoped>
.company-card-header {
  position: relative;
  margin: 0 calc(-1 * $gap);
  padding: 0 $gap $gap;
  border-bottom: 1px solid $border-color;
}

.page-header {
  font-size: 1.25rem;
  font-weight: bolder;
  text-align: center;
}

.go-back-btn {
  position: absolute;
  left: $gap;
  bottom: $gap-half;
  display: flex;
  align-items: center;
  height: 100%;

  font-size: 0.875rem;
  line-height: 0.875rem;
  cursor: pointer;

  .go-back-icon {
    margin-right: 15px;
  }
}
</style>
