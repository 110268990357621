<template>
  <div class="invoice-table__wrap">
    <table class="invoice-table">
      <thead>
        <tr>
          <template v-for="header in tableHeader" :key="header.key">
            <th
              v-if="checkTableHeaderDisplay(header)"
              :id="header.key"
              :class="header.key"
              @click="() => onClickTableHeader(header.key)"
            >
              {{ header.label }}
              <IconTriangleBottom class="sort-condition-icon" />
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in tableData" :key="row.id">
          <td v-if="isSelectedAll">
            <span
              v-if="(row as ICompanySetting).invoice_targetted"
              class="targetted"
            >
              <IconCheck />
              対象
            </span>
          </td>
          <td v-else>
            <span class="status" 
              :class="(row as ICompanyInvoice).status"
              @click="() => openChangeStatusModal(row as ICompanyInvoice)">
              <IconCheckGreen v-if="(row as ICompanyInvoice).status === 'billed'" 
              />
              {{ translateStatus((row as ICompanyInvoice).status) }}
            </span>
          </td>
          <td>
            <span
              class="account_name_td"
              @click="() => onClickAccountName(row.company_id)"
            >
              {{ row.company_name }}
            </span>
          </td>
          <td>{{ row.labels.contract_plan }}</td>
          <td>{{ row.annually_contract_count || '-' }}</td>
          <td>{{ row.annually_contract_expired_date || '-' }}</td>
          <td>{{ row.annually_contract_memo || '-' }}</td>
          <td>{{ row.monthly_contract_count || '-' }}</td>
          <td>{{ row.monthly_contract_memo || '-' }}</td>
          <td>{{ row.labels.invoice_due_date || '-' }}</td>
          <td>{{ row.labels.transfer_due_date || '-' }}</td>
          <td>{{ row.accounting_operator?.name || '-' }}</td>
          <td>{{ row.cs_operator?.name || '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ChangeStatusModal
    :visible="state.visibleChangeStatusModal"
    :company-invoice="selectedCompanyInvoice"
    :on-close="closeChangeStatusModal"/>

</template>

<script setup lang="ts">
import { defineProps, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { ICompanyInvoice } from "@/models/company-invoice";
import { ICompanySetting } from "@/models/company-setting";
import IconCheck from "@/assets/svgs/icon_check.svg";
import IconCheckGreen from "@/assets/svgs/icon_check_green.svg";
import IconTriangleBottom from "@/assets/svgs/icon_triangle_bottom.svg";
import { ISortDirection } from "@/models/sort-direction";
import ChangeStatusModal from "@/components/common/ChangeStatusModal.vue";

// =========================== Router ===========================
const router = useRouter();
const onClickAccountName = (companyId: number) => {
  router.push({ name: "accounting-company-edit", params: { id: companyId } });
};

// =========================== Props ===========================
interface IProps {
  isSelectedAll: boolean;
  tableData: ICompanyInvoice[] | ICompanySetting[];
  sortCondition: string;
  sortDirection: ISortDirection;
  changeSortCondition: (sortCondition: string) => void;
}

const props = defineProps<IProps>();

const state = reactive({
  visibleChangeStatusModal: false,
});

const selectedCompanyInvoice = ref({} as ICompanyInvoice);

const openChangeStatusModal = (companyInvoice: ICompanyInvoice) => {
  selectedCompanyInvoice.value = companyInvoice;
  state.visibleChangeStatusModal = true;
};

const closeChangeStatusModal = () => {
  state.visibleChangeStatusModal = false;
};

const translateStatus = (status: string) => {
  switch (status) {
    case "billed":
      return "freeeに発行済み";
    case "irregular":
      return "取引担当者から\n変更依頼あり";
    case "checking":
      return "取引担当者確認中";
    default:
      return "";
  }
};

const onClickTableHeader = (headerKey: string) => {
  props.changeSortCondition(headerKey);
};

// =========================== Data ===========================
interface ITableHeader {
  label: string;
  key: string;
  sort_condition: string;
  allRequired?: boolean;
  width?: string;
}

/*
 * allRequired: true => isSelectedAll === true
 * allRequired: false => isSelectedAll === false
 * allRequired: undefined => always true
 */
const checkTableHeaderDisplay = (header: ITableHeader) => {
  if (header.allRequired === undefined) {
    return true;
  } else if (header.allRequired === true) {
    return props.isSelectedAll;
  } else {
    return !props.isSelectedAll;
  }
};

const tableHeader: ITableHeader[] = [
  {
    label: "ステータス",
    key: "status",
    sort_condition: "desc",
    allRequired: false,
  },
  {
    label: "請求管理対象",
    key: "invoice_targetted",
    sort_condition: "desc",
    allRequired: true,
  },
  {
    label: "アカウント名",
    key: "company_name",
    sort_condition: "desc",
  },
  {
    label: "プラン",
    key: "contract_plan",
    sort_condition: "desc",
  },
  {
    label: "年次契約数",
    key: "annually_contract_count",
    sort_condition: "desc",
  },
  {
    label: "年次契約\nの期限",
    key: "annually_contract_expired_date",
    sort_condition: "desc",
  },
  {
    label: "年次契約\nの備考",
    key: "annually_contract_memo",
    sort_condition: "desc",
  },
  {
    label: "月次契約数",
    key: "monthly_contract_count",
    sort_condition: "desc",
  },
  {
    label: "月次契約\nの備考",
    key: "monthly_contract_memo",
    sort_condition: "desc",
  },
  {
    label: "請求期日",
    key: "invoice_due_date",
    sort_condition: "desc",
  },
  {
    label: "振込期日",
    key: "transfer_due_date",
    sort_condition: "desc",
  },
  {
    label: "経理担当",
    key: "accounting_operators_company_invoices.name",
    sort_condition: "desc",
  },
  {
    label: "取引担当",
    key: "operators.name",
    sort_condition: "desc",
  },
];
</script>

<style lang="scss" scoped>
.invoice-table__wrap {
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid $border-color;
    white-space: pre-wrap;
    vertical-align: middle;
    font-size: 0.675rem;
    text-align: center;

    th {
      padding: $gap-half;
      font-weight: bold;
      text-align: center;
      border: 1px solid $border-color;
      background: $lighter-gray;
      vertical-align: middle;
      cursor: pointer;

      &.status,
      &.invoice_targetted {
        width: 122px;
      }

      .sort-condition-icon {
        vertical-align: middle;
      }
    }

    td {
      padding: $gap-half;
      border-bottom: 1px solid $border-color;
      vertical-align: middle;
    }

    svg {
      height: 0.675rem;
    }
    .status {
      font-weight: bolder;
      cursor: pointer;
      svg {
        vertical-align: bottom;
      }

      &.billed {
        color: $green;
      }

      &.irregular {
        color: $alert-color;
      }
    }

    .account_name_td {
      font-weight: bolder;
      color: $orange;
      cursor: pointer;
    }
  }
}
</style>
