<template>
  <BaseModal
    header-text="イレギュラーのため変更"
    :visible="visible"
    :on-close="onClose"
  >
    <label>変更理由を選択</label>
    <Loading v-if="isLoading" :is-loading="isLoading" is-fixed />
    <Select
      v-model="reason"
      :options="options"
      placeholder="変更理由を選択してください"
    />

    <Textarea
      v-model="reasonComment"
      class="irregular-textarea"
      placeholder="コメントを入力"
    />

    <template #footer>
      <Btn
        text="キャンセル"
        btn-type="btn-gray"
        :on-click-event="onClose"
        class="cancel-btn"
      />
      <Btn
        text="決定"
        btn-type="btn-primary"
        :on-click-event="onSubmit"
        class="submit-btn"
      />
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, defineProps, withDefaults, watch } from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import Select from "@/components/common/form/Select.vue";
import Textarea from "@/components/common/form/Textarea.vue";
import Btn from "@/components/common/Btn.vue";
import irregularReasonApi from "@/api/irregular-reason";
import CompanyInvoiceApi from "@/api/company-invoice";
import Loading from "@/components/common/Loading.vue";
import { ISelectOption } from "@/models/select-option";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
  visible: boolean;
  onClose?: () => void;
  headerText?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  onClose: () => {
    return;
  },
});

const reason = ref("as_usual");
const reasonComment = ref("");

const options = ref<ISelectOption[]>([]);

const isLoading = ref(false);
watch(
  () => props.visible,
  async (visible) => {
    if (visible) {
      isLoading.value = true;
      await irregularReasonApi.getIrregularReasons().then((res) => {
        options.value = res;
      });
      isLoading.value = false;
    } else {
      options.value = [];
    }
  }
);

const onSubmit = async () => {
  if (isLoading.value) return;

  await CompanyInvoiceApi.updateCompanyInvoiceStatusToIrregular(
    props.companyInvoice.id,
    reason.value,
    reasonComment.value
  ).then(() => {
    // reload する
    location.reload();
  });
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: $gap-half;
}
.cancel-btn {
  margin-right: $gap-half;
}

.irregular-textarea {
  margin-top: $gap-half;
}

.sp {
  label {
    font-size: 0.875rem;
  }

  .cancel-btn,
  .submit-btn {
    min-width: auto;
    width: 100%;
  }
}
</style>
