<template>
  <div class="card">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.card {
  padding: $gap;
  border: 1px solid $border-color;
  border-radius: $radius;
  background: $white;
  box-shadow: $shadow;
}

.sp {
  .card {
    padding: $gap-half;
  }
}
</style>
