import ApiClientWithCredentials from "@/lib/api-client";
import Settings from "@/constants/settings";
import { AxiosResponse } from "axios";
import { ISelectOption } from "@/models/select-option";

export default {
  async getInvoiceShippingMethods(): Promise<ISelectOption[]> {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/invoice_shipping_methods`
    ).then((response: AxiosResponse<ISelectOption[]>) => {
      return response.data as ISelectOption[];
    });
  },
};
