import ApiClientWithCredentials from "@/lib/api-client";
import { ActionTree } from "vuex";
import { IState } from "./types";
import { IOperator } from "@/models/operator";
import Settings from "@/constants/settings";

const actions: ActionTree<IState, IState> = {
  signin: async ({ commit }, payload: { email: string; password: string }) => {
    return await ApiClientWithCredentials.post(
      `${Settings.API_V1}/auth/sign_in`,
      {
        email: payload.email,
        password: payload.password,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then((response) => {
        commit("signin", response.data as IOperator);
        return true;
      })
      .catch((error) => {
        alert(error.response.data.errors.join('\n'))
        console.log(error);
        return false;
      });
  },
  signout: async ({ commit }) => {
    return await ApiClientWithCredentials.delete(
      `${Settings.API_V1}/auth/sign_out`
    )
      .then(() => {
        commit("signout");
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  checkAuth: async ({ commit }) => {
    return await ApiClientWithCredentials.get(
      `${Settings.API_V1}/current_operator`
    )
      .then((response) => {
        commit("signin", response.data as IOperator);
        return true;
      })
      .catch(() => {
        return false;
      });
  },
};

export default actions;
