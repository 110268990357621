import { MutationTree } from "vuex";
import { IState } from "./types";
import { IOperator } from "@/models/operator";

const mutations: MutationTree<IState> = {
  signin: (state, payload: IOperator) => {
    state.isLoggedIn = true;
    state.currentOperator = payload;
    state.role = payload.role;
  },
  signout: (state) => {
    state.isLoggedIn = false;
    state.currentOperator = null;
    state.role = null;
  },
  setDate: (state, dateStr) => {
    console.log("setDate", dateStr);
    state.date = dateStr;
  }
};

export default mutations;
