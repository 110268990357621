import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/styles/reset.scss";
import "@/assets/styles/common.scss";

const app = createApp(App);
app.use(router);
app.use(store);
app.component("VueDatePicker", VueDatePicker);

app.mount("#app");
