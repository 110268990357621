import { IState } from "./types";

const state: IState = {
  isLoggedIn: false,
  currentOperator: null,
  role: null,
  date: null
};

export default state;
