<template>
  <div class="card-invoice-info">
    <ListRow :columns="columns" vertical half-gap />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import ListRow from "@/components/common/list/ListRow.vue";
import { ICompanyInvoice } from "@/models/company-invoice";

interface IProps {
  companyInvoice: ICompanyInvoice;
}
const props = defineProps<IProps>();

const columns = [
  {
    header: "宛名",
    text: props.companyInvoice.invoice_address || "-"
  },
  {
    header: "To",
    text: props.companyInvoice.invoice_email || "-"
  },
  {
    header: "請求期日",
    text: props.companyInvoice.labels.invoice_due_date || "-"
  },
  {
    header: "振込期日",
    text: props.companyInvoice.labels.transfer_due_date || "-"
  },
  {
    header: "送付方法",
    text: props.companyInvoice.labels.invoice_shipping_method || "-"
  },
];
</script>

<style lang="scss" scoped></style>
