<template>
  <Loading v-if="isInitialLoading" :is-loading="isInitialLoading" is-fixed />
  <template v-else>
    <Loading v-if="isLoading" :is-loading="isLoading" is-fixed />
    <CardHeader
      class="mb-gap"
      :selected-year-month="selectedYearMonth"
      :year-month-list="yearMonthList"
      :change-selected-year-month="changeSelectedYearMonth"
    />
    <CardWrap>
      <CardInvoiceTable
        v-if="selectedYearMonth !== 'all'"
        :is-selected-all="false"
        v-model:table-data="companyInvoices"
        :sort-condition="state.sortCondition"
        :sort-direction="state.sortDirection"
        :change-sort-condition="changeSortCondition"
      />

      <CardInvoiceTable
        v-if="selectedYearMonth === 'all'"
        :is-selected-all="true"
        :table-data="companySettings"
        :sort-condition="state.sortCondition"
        :sort-direction="state.sortDirection"
        :change-sort-condition="changeSortCondition"
      />
    </CardWrap>
  </template>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, reactive } from "vue";
import CardWrap from "@/components/common/CardWrap.vue";
import CardHeader from "./CardHeader.vue";
import CardInvoiceTable from "./CardInvoiceTable.vue";
import Loading from "@/components/common/Loading.vue";
import { ICompanyInvoice } from "@/models/company-invoice";
import { ICompanySetting } from "@/models/company-setting";
import { ISelectOption } from "@/models/select-option";
import { ISortDirection } from "@/models/sort-direction";
import companyInvoiceApi, { IGetCompanyInvoicesParams } from "@/api/company-invoice";
import companySettingApi, { IGetCompanySettingsParams } from "@/api/company-setting";
import companyInvoicesMonthApi from "@/api/company-invoices-month";
import store from "@/store";

const isInitialLoading = ref(true);
const companyInvoices = ref<ICompanyInvoice[]>([]);
const companySettings = ref<ICompanySetting[]>([]);
const companyInvoicesMonth = ref<string[]>([]);
const selectedYearMonth = ref<string>("all");

onMounted(async () => {
  companyInvoicesMonthApi.getCompanyInvoicesMonths().then(async (res) => {
    companyInvoicesMonth.value = res;

    selectedYearMonth.value = store.state.date || companyInvoicesMonth.value[0];

    await getCompanyInvoicesOrSettings();

    isInitialLoading.value = false;
  });
});

const yearMonthList = computed((): ISelectOption[] => {
  const convertYearMonth = (yearMonth: string) => {
    const year = yearMonth.slice(0, 4);
    const month = yearMonth.slice(5, 7);
    return `${year}年${month}月`;
  };

  const convertedInvoicesMonth = companyInvoicesMonth.value.map((month) => {
    return {
      value: month,
      label: convertYearMonth(month),
    };
  });

  return [{ value: "all", label: "すべて" }, ...convertedInvoicesMonth];
});

const isLoading = ref(false);

const selectedYearMonthParams = computed((): IGetCompanyInvoicesParams | IGetCompanySettingsParams => {
  if (selectedYearMonth.value === "all") return {};

  const year = selectedYearMonth.value.slice(0, 4);
  const month = selectedYearMonth.value.slice(5, 7);
  return { year, month };
});

const getCompanyInvoices = async () => {
  // isLoading.value = true;
  companyInvoiceApi
    .getCompanyInvoices(selectedYearMonthParams.value)
    .then((res) => {
      companyInvoices.value = res;
      isLoading.value = false;
    });
};

const getCompanySettings = async () => {
  // isLoading.value = true;
  companySettingApi
    .getCompanySettings(selectedYearMonthParams.value)
    .then((res) => {
      companySettings.value = res;
      isLoading.value = false;
    });
};

const getCompanyInvoicesOrSettings = () => {
  if (selectedYearMonth.value === "all") {
    getCompanySettings();
  } else {
    getCompanyInvoices();
  }
};

const changeSelectedYearMonth = (e: Event) => {
  if (isLoading.value) return;

  isLoading.value = true;
  selectedYearMonth.value = (e.target as HTMLInputElement).value;

  store.commit('setDate', selectedYearMonth.value)

  getCompanyInvoicesOrSettings();
};

interface IState {
  sortCondition: string;
  sortDirection: ISortDirection;
}

const state = reactive<IState>({
  sortCondition: "id",
  sortDirection: "desc",
});

const changeSortCondition = (sortCondition: string) => {
  if (isLoading.value) return;

  if (state.sortCondition === sortCondition) {
    state.sortDirection = state.sortDirection === "desc" ? "asc" : "desc";
  } else {
    state.sortDirection = "desc";
  }
  state.sortCondition = sortCondition;

  isLoading.value = true;

  if (selectedYearMonth.value === "all") {
    let params: IGetCompanySettingsParams = {
      sortCondition: state.sortCondition,
      sortDirection: state.sortDirection,
    };

    companySettingApi.getCompanySettings(params).then((res) => {
      companySettings.value = res;
      isLoading.value = false;
    });
  } else {
    let params: IGetCompanyInvoicesParams = {
      sortCondition: state.sortCondition,
      sortDirection: state.sortDirection,
      ...selectedYearMonthParams.value,
    };

    companyInvoiceApi.getCompanyInvoices(params).then((res) => {
      companyInvoices.value = res;
      isLoading.value = false;
    });
  }

};
</script>

<style lang="scss" scoped></style>
