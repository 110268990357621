import { useBreakpoints } from "@vueuse/core";
import { onMounted, onUnmounted } from "vue";

export const useIsPcView = () => {
  const breakpoints = useBreakpoints({
    pc: 768,
  });

  const isPcView = breakpoints.pc;

  const addSpClassToHtml = () => {
    document.documentElement.classList.add("sp");
  };

  const removeSpClassFromHtml = () => {
    document.documentElement.classList.remove("sp");
  };

  const checkBrowserSize = () => {
    // SPの場合はhtml要素にspクラスを付与
    if (!isPcView.value) {
      addSpClassToHtml();
    } else {
      removeSpClassFromHtml();
    }
  };

  onMounted(() => {
    checkBrowserSize();
    window.addEventListener("resize", checkBrowserSize);
  });

  onUnmounted(() => {
    removeSpClassFromHtml();
    window.removeEventListener("resize", checkBrowserSize);
  });

  return isPcView;
};
