import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
// import HomeView from "@/views/HomeView.vue";
import SignIn from "@/components/signin/new.vue";
import AccountingIndex from "@/components/accounting/index.vue";
import CsIndex from "@/components/cs/index.vue";
import CompanyEdit from "@/components/company/edit.vue";
import { authenticationGuard } from "@/router/auth-guard";

/*
 * authenticationGuard によりナビゲーションガードを行っている

 * 経理担当(role === "accounting")にのみ遷移を許可したい場合は、
 * meta オプションに requiresAccountingAuth: true を追加する。
 *
 * CS担当(role === "cs")にのみ遷移を許可したい場合は、
 * meta オプションに requiresCsAuth: true を追加する。
 *
 * 認証中に遷移を許可したくない場合（サインインページ等）は、
 * meta オプションに requiresNotAuth: true を追加する。
 */

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "signin",
    component: SignIn,
    meta: { requiresNotAuth: true },
  },
  {
    path: "/accounting/invoices",
    name: "accounting-invoices",
    component: AccountingIndex,
    meta: { requiresAccountingAuth: true },
  },
  {
    path: "/accounting/company/:id",
    name: "accounting-company-edit",
    component: CompanyEdit,
    meta: { requiresAccountingAuth: true },
  },
  {
    path: "/cs",
    name: "cs",
    component: CsIndex,
    meta: { requiresCsAuth: true },
  },
  {
    path: "/cs/company/:id",
    name: "cs-company-edit",
    component: CompanyEdit,
    meta: { requiresCsAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

authenticationGuard(router);

export default router;
